<template>
  <v-app id="login" class="warning">
    <v-content>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md5 lg4>
            <v-card class="elevation-1 pa-3">
              <v-card-text>
                <div class="layout column align-center">
                  <img src="/static/m.png" alt="Vue Material Admin" width="120" height="120" />
                  <h1 class="flex my-4 warning--text">SGC - Recuperar Cuenta</h1>
                </div>
                <v-form>
                  <v-text-field
                    append-icon="person"
                    name="name"
                    label="Nombre de Usuario"
                    type="text"
                    required
                    autofocus
                    v-model="usuario.username"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-btn icon title="Iniciar Sesión" color="success" to="/login">
                  <v-icon>fingerprint</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn block color="warning" @click="recuperar" :loading="loading">Reecuperar</v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
export default {
  layout: "default",
  name: "Recuperar",
  data: () => ({
    loading: false,
    usuario: {
      name: ""
    }
  }),
  methods: {
    recuperar() {}
  }
};
</script>